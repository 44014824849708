<template>
    <div>
      <b-overlay
        :show="isLoading"
        rounded
        opacity="0.6"
        spinner-variant="primary"
        spinner-mediam
      >
        <b-row>
          <b-col cols="12" class="text-right mb-1">
            <b-button
              v-b-toggle.sidebar-right
              variant="primary"
              size="sm"
            >
              <feather-icon icon="FilterIcon" />
              {{$t('Control')}}
            </b-button>
            
            <b-sidebar
              id="sidebar-right"
              bg-variant="white"
              right
              backdrop
              shadow
            >
              <orders-filters ref="ordersFilters"></orders-filters>
            </b-sidebar>
          </b-col>
        </b-row>
        <b-row class="match-height" v-if="!isLoading">
          <template v-if="orders && Object.keys(orders.data).length > 0">
          <template v-for="(data,index) in orders.data">
            <transition
            :name="$store.state.appConfig.layout.routerTransition"
            mode="out-in" :key="index"
            >
              <b-col cols="12">
                <b-link :to="`/orders/`+data.id">
                  <b-card>
                    <b-row>
                      <b-col md="9" sm="9">
                        <div>
                          <!-- <b-form-checkbox name="checkbox">
                          </b-form-checkbox> -->
                          <h4 class="text-primary">#{{ data.id }}</h4>
                          <b-card-text class="text-gray">
                              {{ data.created_at | formatDate }}
                          </b-card-text>

                          <h4>
                            {{data.username}}
                          </h4>
                          <p class="text-secondary mb-0">
                            <b-link :href="'https://wa.me/'+data.mobile" target="_blank">
                              {{data.mobile}}
                              <b-img
                                  fluid
                                  :src="require('@/assets/images/icons/whatsapp.png')"
                                  alt="whatsapp"
                                  width="15px"
                                />
                            </b-link>
                          </p>
                          <p class="text-secondary mb-0">
                            {{data.city.country.name}} - {{data.city.name}} - {{data.address_owner}} - {{data.address_details}}
                          </p>
                        </div>
                      </b-col>
                      <b-col md="3" sm="3" align-self="center">
                        <div class="text-center">
                          <h1 class="text-primary">
                            {{data.total_price}}
                            <small>{{$t('SAR')}}</small>
                          </h1>
                          <div v-if="data.status" class="mt-1 mb-1">
                            <h4>
                              <div v-if="data.status.id === 1 || data.status.id === 2">
                                  <b-badge variant="secondary" class="badge-glow my-btn">{{$t(data.status.name)}}</b-badge>
                              </div>
                              <div v-else-if="data.status.id === 3">
                                  <b-badge variant="warning" class="badge-glow my-btn">{{$t(data.status.name)}}</b-badge>
                              </div>
                              <div v-else-if="data.status.id === 4">
                                  <b-badge variant="danger" class="badge-glow my-btn">{{$t(data.status.name)}}</b-badge>
                              </div>
                              <div v-else-if="data.status.id === 6">
                                  <b-badge variant="primary" class="badge-glow my-btn">{{$t(data.status.name)}}</b-badge>
                              </div>
                              <div v-else-if="data.status.id === 7 || data.status.id === 8">
                                  <b-badge variant="info" class="badge-glow my-btn">{{$t(data.status.name)}}</b-badge>
                              </div>
                              <div v-else>
                                  <b-badge variant="success" class="badge-glow my-btn">{{$t(data.status.name)}}</b-badge>
                              </div>
                            </h4>
                          </div>
                          <div>
                            <b-img
                              :src="`https://cdn.mhd.sa/asset/images/providers/${data.payment_method.name_en}-logo.png`"
                              height="35"
                              class="ml-1 mr-1"
                              />
                            <b-img
                              :src="`https://cdn.mhd.sa/asset/images/providers/${data.shipping_method.name_en}-logo.png`"
                              height="35"
                              class="ml-1 mr-1"
                              />
                          </div>
                        </div>
                      </b-col>
                    </b-row>
                  </b-card>
                </b-link>
              </b-col>
            </transition>
          </template>
          </template>
          <template v-else>
            <b-col>
              <b-card class="text-center">
                  <span>{{$t('content not found')}}.</span>
              </b-card>
            </b-col>
          </template>
        </b-row>
        <pagination v-if="orders" :limit="7" :data="orders" @pagination-change-page="getOrders"></pagination>
      </b-overlay>
    </div>
</template>

<script>
import useJwt from '@/auth/useJwt'
import OrdersFilters from './OrdersFilters.vue'

export default {
  components: { OrdersFilters },
    name: 'Orders',
    data() {
      return {
        orders: null,
        isLoading: false,
        filter: {}
      }
    },
    mounted() {
      this.getOrders();
    },
    methods: {
      getOrders(page = 1){
        let params = {}
        if (Object.keys(this.$refs.ordersFilters.form).length) {

          if (this.$refs.ordersFilters.form.last_status_id) {
            params.last_status_id = this.$refs.ordersFilters.form.last_status_id.id
          }
          if (this.$refs.ordersFilters.form.id) {
            params.id = this.$refs.ordersFilters.form.id
          }
          if (this.$refs.ordersFilters.form.mobile) {
            params.mobile = this.$refs.ordersFilters.form.mobile
          }
          if (this.$refs.ordersFilters.form.email) {
            params.email = this.$refs.ordersFilters.form.email
          }
          if (this.$refs.ordersFilters.form.username) {
            params.username = this.$refs.ordersFilters.form.username
          }
          if (this.$refs.ordersFilters.form.city_id) {
            params.city_id = this.$refs.ordersFilters.form.city_id.id
          }
          if (this.$refs.ordersFilters.form.from_date) {
            params.from_date = this.$refs.ordersFilters.form.from_date
          }
          if (this.$refs.ordersFilters.form.to_date) {
            params.to_date = this.$refs.ordersFilters.form.to_date
          }
          if (this.$refs.ordersFilters.form.payment_method_id) {
            params.payment_method_id = this.$refs.ordersFilters.form.payment_method_id.id
          }
          if (this.$refs.ordersFilters.form.shipping_method_id) {
            params.shipping_method_id = this.$refs.ordersFilters.form.shipping_method_id.id
          }
        } 

        params.page = page

        this.isLoading = true
        useJwt.get('/orders/get-all',{params: params})
        .then((response) => {
          // console.log(response.data.data)
          this.orders = response.data.data
          if(response.data.data.data.length != 0) {
              this.isEmpty = false;
          }
          this.isLoading = false
        })
        .catch(response => {
          this.isLoading = false
          // console.log(response);
        });
      }
    }
}
</script>
